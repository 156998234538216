<template>
    <v-chart class="chart" :option="option" />
</template>
  
<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, BarChart } from "echarts/charts";
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    ToolboxComponent,
    GridComponent,
    MarkLineComponent,
    MarkPointComponent
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { max } from "zrender/lib/core/vector";
import { rotate } from "zrender/lib/core/matrix";

use([
    CanvasRenderer,
    LineChart,
    BarChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    ToolboxComponent,
    GridComponent,
    MarkLineComponent,
    MarkPointComponent
]);

export default {
    props: ['timeline', 'datData','ratData','maxTemp','minTemp'],
    components: {
        VChart
    },
    provide: {
        // [THEME_KEY]: "dark"
    },
    mounted() {
        console.log(this.maxTemp);
    },
    data() {
        return {
            option: {
                title: {
                    text: 'Templog Readings',
                        left: 'center',
                        top: 20,
                        textStyle: {
                            // color: '#333',
                            fontSize: 14
                        }
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: ['DAT', 'RAT'],
                    textStyle: {
                        color: "white"
                    }
                },
                toolbox: {
                    show: true,
                    feature: {
                        magicType: { type: ['line', 'bar'] },
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: this.timeline,
                    axisLabel: {
                        color: "white",
                        margin: 5,
                        hideOverlap: true
                    },
                    name: 'Date',
                    nameTextStyle: {
                        color: 'white',
                        padding: 20,
                        fontSize: 15
                    },
                    nameLocation: 'middle',
                    nameRotate: 0,
                    nameAlign: 'center',                    
                },
                yAxis: {
                    type: 'value',
                    // max: Math.round((Math.round(this.settings.maxTemp) + 25) / 5) * 5,
                    // min: Math.round((Math.round(this.settings.minTemp) - 10) / 5) * 5,
                    axisLabel: {
                        formatter: '{value} °C',
                        color: "white",
                        margin: 5,
                        hideOverlap: true

                    },
                    name: 'Temperature',
                    nameTextStyle: {
                        color: 'white',
                        padding: 30,
                        fontSize: 15

                    },
                    nameLocation: 'middle',
                    nameGap: 30,
                    nameRotate: 90,
                    nameAlign: 'center',
                },
                series: [
                    {
                        name: 'DAT',
                        type: 'line',
                        color: 'lightGreen',
                        data: this.datData,  
                    },    
                    {
                        name: 'RAT',
                        type: 'line',
                        color: 'lightBlue',
                        data: this.ratData,
                    },
                      {
                        name: 'Max',
                        type: 'line',
                        color: 'red',
                        data: Array(this.datData.length).fill(this.maxTemp),
                        showInLegend: false,
                        enableMouseTracking: false,
                        lineStyle: {
                            type: 'dashed'
                        },
                    },
                    {
                        name: 'Min',
                        type: 'line',
                        color: 'blue',
                        data: Array(this.datData.length).fill(this.minTemp),
                        showInLegend: false,
                        enableMouseTracking: false,
                        lineStyle: {
                            type: 'dashed'
                        },
                    }
                ]
            }
        };
    }
};
</script>
  
<style scoped>
.chart {
    height: 65vh;
}
</style>